

:root {
    --base: #263238;
    --lite:  #FFF;
    --brand: #FF3D00;
    --size: 48px;
  }

.loader-17{
    width : --size;
    height: --size;
    border-radius: 50%;
    display: inline-block;
    border-top:3px solid --lite;
    border-right:3px solid transparent;
    animation: rotation 1s linear infinite;
  }