@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  border: 1px solid red;
} */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
